html {
  font-size: 14px;
}

#root {
  height: 100vh;
  width: 100vw;
  display: grid;
  background: #fff;
}

/* scrollbar */
* {
  /* IE */
  scrollbar-face-color: #596975;
  scrollbar-shadow-color: #596975;
  scrollbar-darkshadow-color: #596975;
  scrollbar-track-color: rgba(217, 228, 235, 0.75);
  scrollbar-arrow-color: #596975;
}

/* Chrome */
*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
*::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
*::-webkit-scrollbar-thumb {
  background: #DBDDDE;
  border-radius: 8px;
}
*::-webkit-scrollbar-track{
  background: unset;
}
